import { tns } from 'tiny-slider/src/tiny-slider.module';
import { mapStyle } from './styles/mapStyle';

app.home = () => {
  function init() {
    setupSlider();
    homeSlider();
    startGoogleMap();
    let tabs = document.querySelectorAll('a.tab');
    for (let tab of tabs) {
      tab.addEventListener('click', tabClicked);
    }
    document.querySelector('#region-select_').addEventListener('change', selectClicked);
  }

  let setupSlider = () => {
    const sliderOpts = {
      container: '.spud-banner-set',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      controls: false,
      responsive: true,
      autoplayButtonOutput: false,
      autoWidth: true,
      autoHeight: true
    };
    const slider = tns(sliderOpts);
  };

  let homeSlider = () => {
      const homeOpts = {
        container: '.logo-client',
        items: 2,
        slideBy: 1,
        speed: 1000,
        autoplay: true,
        controls: false,
        responsive: {
          400: {
            items: 4
          },
          992: {
            items: 9
          }
        },
        nav: false,
        autoplayButtonOutput: false
      };
    const slider = tns(homeOpts);
  };


  let startGoogleMap = () => {
    let map = new google.maps.Map(document.getElementById('map-banner'), {
      zoom: 17,
      minizoom: 17,
      // The latitude and longitude to center the map (always required)
      disableDefaultUI: true,
      gestureHandling: 'cooperative',
      scrollwheel: false,
      zoomControl: true,
      styles: mapStyle
    });
    let mapElement = document.getElementById('map-banner');
    let pointMarkers = mapElement.getAttribute('data-location');
    let marked = JSON.parse(pointMarkers);
    let bounds = new google.maps.LatLngBounds();

    marked.forEach(marked => {
      if (marked.lat == null) return;
      if (marked.lng == null) return;

      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(marked.lat, marked.lng),
        map: map,
        icon: '/marker-blue.png',
        title: marked.name
      });
      bounds.extend(marker.position);
    });
    map.fitBounds(bounds);

    // Maps zoom fix - https://stackoverflow.com/questions/2437683/google-maps-api-v3-can-i-setzoom-after-fitbounds
    var listener = google.maps.event.addListener(map, "idle", function() { 
      if (map.getZoom() > 17) map.setZoom(17); 
      google.maps.event.removeListener(listener); 
    });
  };

  //icon: marked.icon to marker-blue
  let tabClicked = e => {
    e.preventDefault();
    const tabTarget = e.currentTarget.getAttribute('data-index');
    updateDisplayedTabs(tabTarget);
  };

  let selectClicked = e => {
    e.preventDefault();
    const tabTarget = e.target.value;
    updateDisplayedTabs(tabTarget);
  };

  let updateDisplayedTabs = tabTarget => {
    let tabs = document.querySelectorAll('a.tab');
    for (let tab of tabs) {
      const tabIndex = tab.getAttribute('data-index');
      if (tabIndex === tabTarget) {
        tab.classList = 'active tab';
      } else {
        tab.classList = 'tab';
      }
    }
    let tabContents = document.querySelectorAll('.region-tab');
    for (let content of tabContents) {
      const index = content.getAttribute('data-index');
      if (index === tabTarget) {
        content.setAttribute('data-active', 'true');
      } else {
        content.setAttribute('data-active', 'false');
      }
    }
  };

  return init();
};
